/* global moment:true, _:true*/
(function () {
    'use strict';

    angular.module('eDocumentsApp').controller('MyDocumentsController', MyDocumentsController);

    MyDocumentsController.$inject = [
        '$state',
        '$scope',
        '$uibModal',
        '$translate',
        'DataUtils',
        'Document',
        'DocumentSearchByUser',
        'ParseLinks',
        'AlertService',
        'paginationConstants',
        'communicationDefinition',
        'origin',
        'destination',
        'entity',
        'Notification',
        'PaginationUtil',
        'DocumentDownloadPdf',
        'CurrencyUtils',
        '$window',
        '$log',
    ];

    function MyDocumentsController(
    $state,
    $scope,
    $uibModal,
    $translate,
    DataUtils,
    Document,
    DocumentSearchByUser,
    ParseLinks,
    AlertService,
    paginationConstants,
    communicationDefinition,
    origin,
    destination,
    entity,
    Notification,
    PaginationUtil,
    DocumentDownloadPdf,
    CurrencyUtils,
    $window,
    $log
  ) {
        var vm = this;
        vm.origin = origin;
        vm.destination = destination;
        vm.entity = entity;
        vm.communicationDefinition = communicationDefinition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.downloaded = downloaded;
        vm.exportDocumentsFiles = exportDocumentsFiles;
        vm.exportSelectedDocsToExcel = exportSelectedDocsToExcel;
        vm.exportSelectedDocsToCSV = exportSelectedDocsToCSV;
        vm.exportAllDocsAsCSV = exportAllDocsAsCSV;
        vm.search = search;
        vm.reset = reset;
        vm.loadAll = loadAll;
        vm.initFilters = initFilters;
        vm.totalItems = 1000;
        vm.isTableLoading = true;
        vm.deselectOne = deselectOne;
        vm.selectAll = selectAll;
        vm.selectChange = selectChange;
        vm.getTotalSelected = getTotalSelected;
        vm.viewSelectedDocuments = viewSelectedDocuments;
        vm.isSearchActive = isSearchActive;
        vm.getCurrency = CurrencyUtils.getCurrency;
        vm.download = download;
        vm.changeFiles = changeFiles;
        vm.changeVisualization = changeVisualization;
        vm.changeDocumentType = changeDocumentType;
        vm.changeStatus = changeStatus;
        vm.getViewableFilename = DataUtils.getViewableFilename;
        vm.formatFileSize = DataUtils.formatFileSize;
        vm.getFileIconClass = DataUtils.getFileIconClass;
        vm.types = ['INVOICE', 'CREDITNOTE', 'DEBITNOTE', 'SELFBILLED', 'PROFORMAINVOICE', 'CONSIGNMENTNOTE', 'ORDER'];
        vm.states = ['IN_COMMUNICATION', 'PROCESSING', 'WAITING', 'SCHEDULED', 'FINISHED', 'ERROR', 'DUPLICATED'];

        vm.documentsToExport = {};

        vm.readOnly = true;
        vm.filtersBackup = {};
        vm.saveChanges = saveChanges;

        function isSmallScreen() {
            var w = angular.element($window);
            vm.width = w.width();
            $scope.$apply();
        }

        $(window).resize(function () {
            isSmallScreen();
        });

        function isSearchActive() {
            if (vm.filters && vm.filtersBackup.filterBy) {
                for (var x in vm.filters.filterBy) {
                    if (vm.filters.filterBy[x] && vm.filters.filterBy[x].startDate) {
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].startDate === '') {
                            vm.filters.filterBy[x].startDate = undefined;
                        }
                        if (vm.filters.filterBy[x] && vm.filtersBackup.filterBy[x] && vm.filters.filterBy[x].startDate !== vm.filtersBackup.filterBy[x].startDate) {
                            return true;
                        }
                    }
                    if (vm.filters.filterBy[x] && vm.filters.filterBy[x].endDate) {
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].endDate === '') {
                            vm.filters.filterBy[x].endDate = undefined;
                        }
                        if (vm.filters.filterBy[x] && vm.filtersBackup.filterBy[x] && vm.filters.filterBy[x].endDate !== vm.filtersBackup.filterBy[x].endDate) {
                            return true;
                        }
                    }
                    if (angular.isDefined(vm.filters.filterBy[x] && vm.filters.filterBy[x].startValue)) {
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].startValue === '') {
                            vm.filters.filterBy[x].startValue = undefined;
                        }
                        if (vm.filters.filterBy[x] && vm.filtersBackup.filterBy[x] && vm.filters.filterBy[x].startValue !== vm.filtersBackup.filterBy[x].startValue) {
                            return true;
                        }
                    }
                    if (angular.isDefined(vm.filters.filterBy[x] && vm.filters.filterBy[x].endValue)) {
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].endValue === '') {
                            vm.filters.filterBy[x].endValue = undefined;
                        }
                        if (vm.filters.filterBy[x] && vm.filtersBackup.filterBy[x] && vm.filters.filterBy[x].endValue !== vm.filtersBackup.filterBy[x].endValue) {
                            return true;
                        }
                    }
                    if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value === '') {
                        vm.filters.filterBy[x].value = undefined;
                    }
                    if (vm.filters.filterBy[x] && vm.filtersBackup.filterBy[x] && vm.filters.filterBy[x].value !== vm.filtersBackup.filterBy[x].value) {
                        return true;
                    }
                }
            }
            return false;
        }

        function saveChanges() {
            DocumentSearchByUser.save(
        { searchString: JSON.stringify(vm.filters.filterBy) },
        function (res) {
            vm.filters.filterBy = JSON.parse(res.searchString);
            vm.filtersBackup = angular.copy(vm.filters);
            vm.page = vm.filters.currentPage + 1;
            vm.predicate = vm.filters.orderBy;
            vm.reverse = vm.filters.orderReverse;
            vm.readOnly = true;
            vm.loadAll();
        },
        function (err) {
            $log.error(err);
        }
      );
        }

        function changeVisualization(value) {
            vm.filters.filterBy.documentViews.value = value;
        }

        function changeFiles(value) {
            vm.filters.filterBy.documentFiles.value = value;
        }

        function changeDocumentType(value) {
            vm.filters.filterBy.documentType.value = value;
        }

        function changeStatus(value) {
            vm.filters.filterBy.status.value = value;
        }

        function reset() {
            vm.filters = angular.copy(vm.filtersBackup);
            loadAll();
        }

        function filterBy() {
            return {
                documentNumber: {
                    name: 'documentNumber',
                    value: undefined,
                },
                origin: {
                    name: 'origin.name',
                    value: undefined,
                },
                createdDate: {
                    name: 'createdDate',
                    startDate: undefined,
                    endDate: undefined,
                },
                documentDate: {
                    name: 'documentDate',
                    startDate: undefined,
                    endDate: undefined,
                },
                documentValue: {
                    name: 'documentValue',
                    startValue: undefined,
                    endValue: undefined,
                },
                documentType: {
                    name: 'documentType',
                    value: undefined,
                },
                documentViews: {
                    name: 'documentViews',
                    value: undefined,
                },
                documentFiles: {
                    name: 'documentFiles',
                    value: undefined,
                },
                status: {
                    name: 'status',
                    value: undefined,
                },
                destination: {
                    name: 'communicationDefinition.destination.name',
                    value: undefined,
                },
            };
        }

        function initFilters() {
            DocumentSearchByUser.get(
        {},
        function (res) {
            vm.filters.filterBy = JSON.parse(res.searchString);
            vm.filtersBackup = angular.copy(vm.filters);
            vm.page = vm.filters.currentPage + 1;
            vm.predicate = vm.filters.orderBy;
            vm.reverse = vm.filters.orderReverse;
            loadAll();
        },
        function () {
            vm.filtersBackup = angular.copy(vm.filters);
            loadAll();
        }
      );
        }

        vm.$onInit = function () {
            vm.filters = PaginationUtil.clearFilters($state.current.name, filterBy());
            initFilters();
        };

        function loadAll() {
            vm.filters = PaginationUtil.updateFilters($state.current.name, vm.page - 1, vm.filters.filterBy, vm.predicate, vm.reverse, vm.itemsPerPage);
            if (vm.origin) {
                Document.findByOrigin(
                    {
                        id: vm.origin.id,
                        page: vm.filters.currentPage,
                        size: vm.filters.itemsPerPage,
                        search: search(),
                        sort: sort(),
                    },
          onSuccess,
          onError
        );
            } else if (vm.destination) {
                Document.findByDestination(
                    {
                        id: vm.destination.id,
                        page: vm.filters.currentPage,
                        size: vm.filters.itemsPerPage,
                        search: search(),
                        sort: sort(),
                    },
          onSuccess,
          onError
        );
            } else if (vm.entity) {
                Document.findByEntity(
                    {
                        id: vm.entity.id,
                        page: vm.filters.currentPage,
                        size: vm.filters.itemsPerPage,
                        search: search(),
                        sort: sort(),
                    },
          onSuccess,
          onError
        );
            } else if (vm.communicationDefinition) {
                Document.findByDefinition(
                    {
                        id: vm.communicationDefinition.id,
                        page: vm.filters.currentPage,
                        size: vm.filters.itemsPerPage,
                        search: search(),
                        sort: sort(),
                    },
          onSuccess,
          onError
        );
            } else {
                Document.query(
                    {
                        page: vm.filters.currentPage,
                        size: vm.filters.itemsPerPage,
                        search: search(),
                        sort: sort(),
                    },
          onSuccess,
          onError
        );
            }

            function sort() {
                if (!vm.predicate) {
                    vm.predicate = 'createdDate';
                }
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'createdDate') {
                    result.push('createdDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.documents = data;

                for (var i = 0; i < vm.documents.length; i++) {
                    if (vm.documentsToExport[vm.documents[i].id] != null) {
                        vm.documents[i].selected = true;
                    }
                }
                checkSelectAll();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function search() {
            var result = '';
            for (var x in vm.filters.filterBy) {
                if (x === 'documentViews') {
                    if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
                        if (vm.filters.filterBy[x].value === '1') {
                            result += vm.filters.filterBy[x].name + '!<:>#AND,';
                        } else if (vm.filters.filterBy[x].value === '0') {
                            result += vm.filters.filterBy[x].name + ':<!>null#AND,';
                        }
                    }
                } else {
                    if (x === 'documentFiles') {
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
                            if (vm.filters.filterBy[x].value === '1') {
                                result += vm.filters.filterBy[x].name + '!<:>#AND,';
                            } else if (vm.filters.filterBy[x].value === '0') {
                                result += vm.filters.filterBy[x].name + ':<!>null#AND,';
                            }
                        }
                    } else {
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
                            result += vm.filters.filterBy[x].name + ':' + vm.filters.filterBy[x].value + '#AND,';
                        }
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].startDate) {
                            if (x === 'documentDate') {
                                result +=
                  vm.filters.filterBy[x].name +
                  '>=' +
                  moment(vm.filters.filterBy[x].startDate, 'DD/MM/YYYY')
                    .tz('Europe/Lisbon')
                    .set({
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                    })
                    .toISOString(true) +
                  '#AND,';
                            } else {
                                result +=
                  vm.filters.filterBy[x].name +
                  '>=' +
                  moment(vm.filters.filterBy[x].startDate, 'DD/MM/YYYY HH:mm')
                    .tz('Europe/Lisbon')
                    .set({
                        minutes: 0,
                        seconds: 0,
                    })
                    .toISOString(true) +
                  '#AND,';
                            }
                        }
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].endDate) {
                            if (x === 'documentDate') {
                                result +=
                  vm.filters.filterBy[x].name +
                  '<=' +
                  moment(vm.filters.filterBy[x].endDate, 'DD/MM/YYYY')
                    .tz('Europe/Lisbon')
                    .set({
                        hours: 23,
                        minutes: 59,
                        seconds: 59,
                    })
                    .toISOString(true) +
                  '#AND,';
                            } else {
                                result +=
                  vm.filters.filterBy[x].name +
                  '<=' +
                  moment(vm.filters.filterBy[x].endDate, 'DD/MM/YYYY HH:mm')
                    .tz('Europe/Lisbon')
                    .set({
                        minutes: 0,
                        seconds: 0,
                    })
                    .toISOString(true) +
                  '#AND,';
                            }
                        }
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].startValue) {
                            result += vm.filters.filterBy[x].name + '>=' + vm.filters.filterBy[x].startValue + '#AND,';
                        }
                        if (vm.filters.filterBy[x] && vm.filters.filterBy[x].endValue) {
                            result += vm.filters.filterBy[x].name + '<=' + vm.filters.filterBy[x].endValue + '#AND,';
                        }
                    }
                }
            }

            return result;
        }

        function downloaded(document, id) {
            return document.documentDownloads.filter(function (documentDownload) {
                return documentDownload.fileId === id;
            }).length;
        }

        function download($event, document, fileId, fileType) {
            DocumentDownloadPdf.download($event, document, fileId, fileType);
        }

        function viewSelectedDocuments() {
            $uibModal.open({
                templateUrl: 'app/entities/document/selected-documents-dialog.html',
                controller: 'SelectedDocumentsController',
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    list: vm.documentsToExport,
                    selectChange: function () {
                        return vm.selectChange;
                    },
                    exportDocumentsFiles: function () {
                        return vm.exportDocumentsFiles;
                    },
                    exportSelectedDocsToCSV: function () {
                        return vm.exportSelectedDocsToCSV;
                    },
                    exportSelectedDocsToExcel: function () {
                        return vm.exportSelectedDocsToExcel;
                    },
                },
            });
        }

        function checkSelectAll() {
            var docsSelected = vm.documents.filter(function (doc) {
                return vm.documentsToExport[doc.id] != null;
            });
            vm.selectedAll = docsSelected.length === vm.documents.length;
        }

        function getTotalSelected() {
            var keys = Object.keys(vm.documentsToExport);
            return keys.length;
        }

        function selectChange(document) {
            if (document.selected) {
                vm.documentsToExport[document.id] = document;
                checkSelectAll();
            } else {
                delete vm.documentsToExport[document.id];
            }
        }

        function selectAll() {
            vm.documents.map(function (x) {
                x.selected = !vm.selectedAll;
                return x;
            });
            if (!vm.selectedAll) {
                for (var i = 0; i < vm.documents.length; i++) {
                    vm.documentsToExport[vm.documents[i].id] = vm.documents[i];
                }
            } else {
                for (var j = 0; j < vm.documents.length; j++) {
                    delete vm.documentsToExport[vm.documents[j].id];
                }
            }
        }

        function deselectOne() {
            vm.selectedAll = false;
        }

        function exportDocumentsFiles() {
            var docsSelected = [];
            Object.keys(vm.documentsToExport).forEach(function (key) {
                docsSelected.push(vm.documentsToExport[key]);
            });
            if (docsSelected.length === 0) {
                Notification.error({ message: $translate.instant('eDocumentsApp.document.message.noDocumentsSelected') });
                return;
            }
            var ids = [];
            for (var i = 0; i < docsSelected.length; i++) {
                if (docsSelected[i].files.length > 0) {
                    ids.push(docsSelected[i].id);
                }
            }
            Document.downloadAsZip(ids).$promise.then(
        function (result) {
            Document.addDownloads(ids);
            DataUtils.viewServerFile(result);
            for (var i = 0; i < vm.documents.length; i++) {
                vm.documents[i].selected = false;
                selectChange(vm.documents[i]);
            }
        },
        function () {
            Notification.error({ message: $translate.instant('eDocumentsApp.document.message.fileRetrievalError') });
        }
      );
        }

        var opts = {
            sheetid: $translate.instant('eDocumentsApp.document.export.title'),
            headers: true,
            columns: [
                {
                    columnid: 'documentNumber',
                    title: $translate.instant('eDocumentsApp.document.documentNumber'),
                    width: 'auto',
                },
                {
                    columnid: 'createdDate',
                    title: $translate.instant('eDocumentsApp.document.createdDate'),
                    width: 'auto',
                },
                {
                    columnid: 'documentDate',
                    title: $translate.instant('eDocumentsApp.document.documentDate'),
                    width: 'auto',
                },
                {
                    columnid: 'documentType',
                    title: $translate.instant('eDocumentsApp.document.documentType'),
                    width: 'auto',
                },
                {
                    columnid: 'documentValue',
                    title: $translate.instant('eDocumentsApp.document.documentValue'),
                    width: 'auto',
                },
                {
                    columnid: 'currency',
                    title: $translate.instant('eDocumentsApp.document.currency'),
                    width: 'auto',
                },
                {
                    columnid: 'origin',
                    title: $translate.instant('eDocumentsApp.document.origin'),
                    width: 'auto',
                },
                {
                    columnid: 'destination',
                    title: $translate.instant('eDocumentsApp.document.destination'),
                    width: 'auto',
                },
                {
                    columnid: 'status',
                    title: $translate.instant('eDocumentsApp.document.status'),
                    width: 'auto',
                },
                {
                    columnid: 'files',
                    title: $translate.instant('eDocumentsApp.document.documentFiles'),
                    width: 'auto',
                    cell: {
                        value: function (value) {
                            var result = '';
                            if (value.length > 0) {
                                for (var i = 0; i < value.length; i++) {
                                    if (i > 0) {
                                        result = result + ', ';
                                    }
                                    result = result + value[i].filename;
                                }
                            }
                            return result;
                        },
                    },
                },
            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'height:20px';
                },
            },
        };

        function exportSelectedDocsToExcel() {
            var docsSelected = [];
            Object.keys(vm.documentsToExport).forEach(function (key) {
                docsSelected.push(vm.documentsToExport[key]);
            });
            if (docsSelected.length === 0) {
                Notification.error({ message: $translate.instant('eDocumentsApp.document.message.noDocumentsSelected') });
                return;
            }
            alasql('SELECT * INTO XLS("documents_' + new Date().getTime() + '.xls",?) FROM ?', [opts, docsSelected]);
        }

        function exportSelectedDocsToCSV() {
            var docsSelected = [];
            Object.keys(vm.documentsToExport).forEach(function (key) {
                docsSelected.push(vm.documentsToExport[key]);
            });
            if (docsSelected.length === 0) {
                Notification.error({ message: $translate.instant('eDocumentsApp.document.message.noDocumentsSelected') });
                return;
            }
            alasql(
        'SELECT documentNumber, createdDate, documentDate, documentType, documentValue, currency, origin, destination, status INTO CSV("documents_' +
          new Date().getTime() +
          '.csv",{headers:true}) FROM ?',
        [docsSelected]
      );
        }

        function exportAllDocsAsCSV() {
            if (vm.totalItems > 15000) {
                $uibModal.open({
                    templateUrl: 'app/entities/document/export-all-documents-error-dialog.html',
                    controller: 'ExportAllDocumentsErrorController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        numberOfDocuments: { total: vm.totalItems },
                    },
                });
            } else {
                var search = { search: vm.search() };
                Document.downloadAllDocsCSV(search).$promise.then(
          function (result) {
              DataUtils.viewServerFile(result);
          },
          function () {
              Notification.error({ message: $translate.instant('eDocumentsApp.document.message.fileRetrievalError') });
          }
        );
            }
        }
    }
})();
