/* global moment:true, _:true*/
(function () {
    'use strict';

    angular.module('eDocumentsApp').controller('AdminDocumentsController', AdminDocumentsController);

    AdminDocumentsController.$inject = [
        '$state',
        '$scope',
        'DataUtils',
        'Document',
        'ParseLinks',
        'AlertService',
        'paginationConstants',
        'PaginationUtil',
        'DocumentDownloadPdf',
        'CurrencyUtils',
        '$window',
    ];

    function AdminDocumentsController(
    $state,
    $scope,
    DataUtils,
    Document,
    ParseLinks,
    AlertService,
    paginationConstants,
    PaginationUtil,
    DocumentDownloadPdf,
    CurrencyUtils,
    $window
  ) {
        var vm = this;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.downloaded = downloaded;
        vm.download = download;
        vm.search = search;
        vm.reset = reset;
        vm.loadAll = loadAll;
        vm.initFilters = initFilters;
        vm.totalItems = 1000;
        vm.isTableLoading = true;
        vm.isSearchActive = isSearchActive;
        vm.getCurrency = CurrencyUtils.getCurrency;
        vm.changeInternalType = changeInternalType;
        vm.getViewableFilename = DataUtils.getViewableFilename;
        vm.formatFileSize = DataUtils.formatFileSize;
        vm.getFileIconClass = DataUtils.getFileIconClass;
        vm.internalTypes = ['GENERIC', 'ELECTRONIC_BILLING', 'DIGITAL_ARCHIVE'];

        vm.documentsToExport = {};

        function isSmallScreen() {
            var w = angular.element($window);
            vm.width = w.width();
            $scope.$apply();
      // return document.body.clientWidth < 500;
        }

        $(window).resize(function () {
            isSmallScreen();
        });

        function isSearchActive() {
            return (
        vm.filters.filterBy.documentNumber.value ||
        angular.isDefined(vm.filters.filterBy.internalType.value) ||
        vm.filters.filterBy.origin.value ||
        vm.filters.filterBy.destination.value ||
        vm.filters.filterBy.month.value ||
        vm.filters.filterBy.year.value
            );
        }

        function changeInternalType(value) {
            if (angular.isUndefined(value)) {
                vm.filters.filterBy.internalType.value = value;
            } else {
                vm.filters.filterBy.internalType.value = '' + value;
            }
            vm.page = 1;
            loadAll();
        }

        function reset() {
            vm.filters = PaginationUtil.clearFilters($state.current.name, filterBy());
            loadAll();
        }

        function filterBy() {
            return {
                documentNumber: {
                    name: 'documentNumber',
                    value: undefined,
                },
                internalType: {
                    name: 'internalType',
                    value: undefined,
                },
                origin: {
                    name: 'origin.name',
                    value: undefined,
                },
                destination: {
                    name: 'communicationDefinition.destination.name',
                    value: undefined,
                },
                year: {
                    name: 'createdDate',
                    value: undefined,
                },
                month: {
                    name: 'createdDate',
                    value: undefined,
                },
            };
        }

        function initFilters() {
            vm.filters = PaginationUtil.getFilters($state.current.name, filterBy());

            vm.page = vm.filters.currentPage + 1;
            vm.predicate = vm.filters.orderBy;
            vm.reverse = vm.filters.orderReverse;
            loadAll();
        }

        vm.$onInit = function () {
            initFilters();
        };

        function loadAll() {
            vm.filters = PaginationUtil.updateFilters($state.current.name, vm.page - 1, vm.filters.filterBy, vm.predicate, vm.reverse, vm.itemsPerPage);
            Document.query(
                {
                    page: vm.filters.currentPage,
                    size: vm.filters.itemsPerPage,
                    search: search(),
                    sort: sort(),
                },
        onSuccess,
        onError
      );

            function sort() {
                if (!vm.predicate) {
                    vm.predicate = 'createdDate';
                }
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'createdDate') {
                    result.push('createdDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.documents = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function search() {
            var result = '';
            for (var x in vm.filters.filterBy) {
                if (x !== 'year' && x !== 'month') {
                    if (vm.filters.filterBy[x] && angular.isDefined(vm.filters.filterBy[x].value) && vm.filters.filterBy[x].value.length > 0) {
                        result += vm.filters.filterBy[x].name + ':' + vm.filters.filterBy[x].value + '#AND,';
                    }
                }
            }
            var tempInit;
            var beginDate;
            var endDate;
            if (angular.isDefined(vm.filters.filterBy.year && vm.filters.filterBy.year.value)) {
                if (angular.isDefined(vm.filters.filterBy.month && vm.filters.filterBy.month.value) && vm.filters.filterBy.month.value !== 0) {
                    tempInit = vm.filters.filterBy.year.value + '/' + vm.filters.filterBy.month.value + '/' + '15';
                    beginDate = moment(tempInit).tz('Europe/Lisbon').startOf('month');
                    if (beginDate.isDST()) {
                        beginDate.add(1, 'hour');
                    }
                    endDate = moment(tempInit).tz('Europe/Lisbon').endOf('month');
                    if (endDate.isDST()) {
                        endDate.add(1, 'hour');
                    }
                    result += vm.filters.filterBy.month.name + '>=' + beginDate.toISOString(true) + '#AND,';
                    result += vm.filters.filterBy.month.name + '<=' + endDate.toISOString(true) + '#AND,';
                } else {
                    tempInit = vm.filters.filterBy.year.value + '/06/15';

                    beginDate = moment(tempInit).tz('Europe/Lisbon').startOf('year');
                    if (beginDate.isDST()) {
                        beginDate.add(1, 'hour');
                    }
                    endDate = moment(tempInit).tz('Europe/Lisbon').endOf('year');
                    if (endDate.isDST()) {
                        endDate.add(1, 'hour');
                    }

                    result += vm.filters.filterBy.month.name + '>=' + beginDate.toISOString(true) + '#AND,';
                    result += vm.filters.filterBy.month.name + '<=' + endDate.toISOString(true) + '#AND,';
                }
            } else {
                if (angular.isDefined(vm.filters.filterBy.month && vm.filters.filterBy.month.value) && vm.filters.filterBy.month.value !== 0) {
                    var date = new Date();
                    var year = date.getFullYear();
                    tempInit = year + '/' + vm.filters.filterBy.month.value + '/' + '15';

                    beginDate = moment(tempInit).tz('Europe/Lisbon').startOf('month');
                    if (beginDate.isDST()) {
                        beginDate.add(1, 'hour');
                    }
                    endDate = moment(tempInit).tz('Europe/Lisbon').endOf('month');
                    if (endDate.isDST()) {
                        endDate.add(1, 'hour');
                    }
                    result += vm.filters.filterBy.month.name + '>=' + beginDate.toISOString(true) + '#AND,';
                    result += vm.filters.filterBy.month.name + '<=' + endDate.toISOString(true) + '#AND,';

                    vm.filters.filterBy.year.value = year;
                }
            }
            return result;
        }

        function downloaded(document, id) {
            return document.documentDownloads.filter(function (documentDownload) {
                return documentDownload.fileId === id;
            }).length;
        }

        function download($event, document, fileId, fileType) {
            DocumentDownloadPdf.download($event, document, fileId, fileType);
        }
    }
})();
